export class AudioPlayer {
  constructor() {
    this.playIconContainer = document.getElementById('play-icon');
    this.audioPlayerContainer = document.getElementById('audio-player-container');
    this.seekSlider = document.getElementById('seek-slider');
    this.volumeSlider = document.getElementById('volume-slider');
    this.muteIconContainer = document.getElementById('mute-icon');
    this.audio = document.querySelector('audio'); // audio element
    this.durationContainer = document.getElementById('duration');
    this.currentTimeContainer = document.getElementById('current-time');
    this.outputContainer = document.getElementById('volume-output');

    this.playState = 'play';
    this.muteState = 'unmute';
    this.raf = null;

    // Check if audio element exists before initializing
    if (this.audio) {
      this.init();
    } else {
      //console.error('Audio element not found. Audio player will not initialize.');
    }
  }

  init() {
    this.setupEventListeners();
    this.setupAudio();
  }

  setupEventListeners() {
    if (this.playIconContainer) {
      this.playIconContainer.addEventListener('click', this.togglePlay.bind(this));
    }
    if (this.muteIconContainer) {
      this.muteIconContainer.addEventListener('click', this.toggleMute.bind(this));
    }
    if (this.seekSlider) {
      this.seekSlider.addEventListener('input', (e) => this.showRangeProgress(e.target));
      this.seekSlider.addEventListener('input', this.updateCurrentTime.bind(this));
      this.seekSlider.addEventListener('change', this.changeCurrentTime.bind(this));
    }
    if (this.volumeSlider) {
      this.volumeSlider.addEventListener('input', (e) => this.showRangeProgress(e.target));
      this.volumeSlider.addEventListener('input', this.updateVolume.bind(this));
    }
  }

  setupAudio() {
    if (this.audio.readyState > 0) {
      this.displayDuration();
      this.setSliderMax();
    } else {
      this.audio.addEventListener('loadedmetadata', () => {
        this.displayDuration();
        this.setSliderMax();
      });
    }
    this.audio.addEventListener('progress', this.displayBufferedAmount.bind(this));
  }

  togglePlay() {
    if (this.playState === 'play') {
      this.audio.play();
      this.playIconContainer?.classList.add('playing');
      requestAnimationFrame(this.whilePlaying.bind(this));
      this.playState = 'pause';
    } else {
      this.audio.pause();
      this.playIconContainer?.classList.remove('playing');
      cancelAnimationFrame(this.raf);
      this.playState = 'play';
    }
  }

  toggleMute() {
    if (this.muteState === 'unmute') {
      this.audio.muted = true;
      this.muteState = 'mute';
    } else {
      this.audio.muted = false;
      this.muteState = 'unmute';
    }
  }

  showRangeProgress(rangeInput) {
    if (rangeInput === this.seekSlider) {
      this.audioPlayerContainer?.style.setProperty('--seek-before-width', `${rangeInput.value / rangeInput.max * 100}%`);
    } else if (rangeInput === this.volumeSlider) {
      this.audioPlayerContainer?.style.setProperty('--volume-before-width', `${rangeInput.value / rangeInput.max * 100}%`);
    }
  }

  calculateTime(secs) {
    const minutes = Math.floor(secs / 60);
    const seconds = Math.floor(secs % 60);
    const returnedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;
    return `${minutes}:${returnedSeconds}`;
  }

  displayDuration() {
    if (this.durationContainer) {
      this.durationContainer.textContent = this.calculateTime(this.audio.duration);
    }
  }

  setSliderMax() {
    if (this.seekSlider) {
      this.seekSlider.max = Math.floor(this.audio.duration);
    }
  }

  displayBufferedAmount() {
    const bufferedAmount = Math.floor(this.audio.buffered.end(this.audio.buffered.length - 1));
    this.audioPlayerContainer?.style.setProperty('--buffered-width', `${(bufferedAmount / this.seekSlider.max) * 100}%`);
  }

  whilePlaying() {
    if (this.seekSlider) {
      this.seekSlider.value = Math.floor(this.audio.currentTime);
    }
    if (this.currentTimeContainer) {
      this.currentTimeContainer.textContent = this.calculateTime(this.seekSlider.value);
    }
    this.audioPlayerContainer?.style.setProperty('--seek-before-width', `${this.seekSlider.value / this.seekSlider.max * 100}%`);
    this.raf = requestAnimationFrame(this.whilePlaying.bind(this));
  }

  updateCurrentTime() {
    if (this.currentTimeContainer) {
      this.currentTimeContainer.textContent = this.calculateTime(this.seekSlider.value);
    }
    if (!this.audio.paused) {
      cancelAnimationFrame(this.raf);
    }
  }

  changeCurrentTime() {
    if (this.seekSlider) {
      this.audio.currentTime = this.seekSlider.value;
    }
    if (!this.audio.paused) {
      requestAnimationFrame(this.whilePlaying.bind(this));
    }
  }

  updateVolume(e) {
    const value = e.target.value;
    if (this.outputContainer) {
      this.outputContainer.textContent = value;
    }
    this.audio.volume = value / 100;
  }
}
